export default {
  translation: {
    cuda: {
      languages: {
        en: "English",
        fr: "Français",
        ja: "日本語",
        de: "Deutsch",
        it: "Italienisch"
      },
      auth: {
        logout: "Ausloggen",
        checking: "Login verifizieren",
        signInError: "Fehler  bim Login. Bitte verifizieren Sie die Login-Daten and versuchen Sie es erneut.",
        switchAccount: "Tenant wechseln",
        unauthorized: "Erlaubnis abgelehn",
        unauthorizedMore: "Sie verfügen nicht über die erforderlichen Berechtigungen für den Zugriff auf diesen Dienst. Bitte wenden Sie sich an Ihren Administrator.",
        defaultAccount: "Standard Tenant",
        profile: "Profil",
        signOut: "Abmelden"
      },
      supportAccess: {
        title: {
          enable: "Support Zugang aktivieren",
          disable: "Support Zugang deaktivieren"
        },
        body: {
          enable: "Aktivieren Sie den Support Zugang, damit die Mitarbeiter des Barracuda Support Konfigurationsänderungen in Ihrem Namen vornehmen können. Alle vom Barracuda Support vorgenommenen Konfigurationsänderungen werden im Audit-Protokoll festgehalten.",
          disable: "Support Zugang deaktivieren. Alle vom Barracuda Support vorgenommenen Konfigurationsänderungen sind im Audit-Protokoll sichtbar."
        }
      },
      layout: {
        hideMenu: "Menü verstecken",
        showMenu: "Menü anzeigen"
      },
      buttons: {
        save: "Sichern",
        add: "Neu",
        edit: "Ändern",
        delete: "Löschen",
        next: "Weiter",
        finish: "Ende",
        reset: "Zurücksetzen",
        cancel: "Abbrechen",
        back: "Zurück",
        confirm: "Bestätigen",
        filters: {
          add: "Neuer Filter",
          clear: "Filter löschen"
        },
        downloadCsv: "CSV  runterladen",
        return: "nach Hause"
      },
      cards: {
        loading: "Laden…",
        noMessages: "Keine Nachrichten verfügbar",
        noData: "Keine Ergebnisse gefunden",
        noListData: "Keine Ergebnisse gefunden",
        noTableData: "Keine Ergebnisse gefunden",
        noEventData: "Keine Ergebnisse gefunden",
        overview: "Gesamt"
      },
      footer: {
        copyright: "Barracuda Networks, Inc.  Alle Rechte vorbehalten.",
        privacyPolicy: "Privacy Policy",
        cookies: "Cookie Settings"
      },
      gdpr: {
        title: "EU-Cookie Akzeptanz",
        body: "Um Ihr Benutzererlebnis zu verbessern, verwendet diese Website Cookies. Durch die weitere Nutzung der Website erklären Sie sich damit einverstanden.",
        link: "<1>Privacy Policy</1> anschauen",
        accept: "Akzeptieren"
      },
      inputs: {
        file: {
          browserUnsupported: "Das Hochladen und Überprüfen von Zertifikaten wird in diesem Browser nicht unterstützt. Bitte verwenden Sie einen aktuellen Firefox-, Chrome-, Edge- oder Safari-Browser.",
          uploadError: "Beim Hochladen/Lesen der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
          buttonLabel: "Datei hochladen"
        },
        select: {
          empty: "Leer"
        },
        autoSelect: {
          generated: "Auto-Generiert"
        },
        selectArray: {
          all: "Alle",
          typeToSearch: "Tippen um zu suchen",
          showing: "{{current}} von {{total}} werden gezeigt. ",
          unknownChipText: "Unbekannt"
        },
        selectSerial: {
          expandSectionButtonText: "fehlenden Secure Connector durch Serial/Linking Code hinzufügen",
          helpText: "Diese finden Sie in Ihrer Bestätigungs-E-Mail von Barracuda Networks",
          searchPlaceholder: "Seriennummer suchen",
          serial: "Seriennummer",
          linkingCode: "Linking Code",
          selectedSerials: "Ausgewählte Seriennummern"
        },
        timeWindow: {
          abbrevDaysOfWeek: {
            mon: "Mon",
            tue: "Die",
            wed: "Mit",
            thu: "Don",
            fri: "Fre",
            sat: "Sam",
            sun: "Son"
          },
          daily: "Däglich",
          from: "Von",
          to: "bis"
        },
        table: {
          noDataMessage: "Nichts hinzugefügt."
        },
        booleanCheckListInput: {
          categories: "Kategorien",
          selectAll: "Alle auswählen",
          selectedItemsStatus: "({{checkedItems}} von {{allItems}} ausgewählt)"
        },
        IconBrowser: {
          iconSelectorText: "Wählen Sie ein vordefiniertes Symbol oder laden Sie ein eigenes hoch.<0></0> Unterstützte Dateiformate: JPEG, PNG, GIF und SVG",
          useCustomText: "Eigenes Symbol verwenden:",
          customApplicationText: "App-Symbol:",
          searchText: "Symbole suchen:",
          placeholderText: "Symbole suchen...",
          noIcons: "Keine Symbole gefunden"
        }
      },
      table: {
        xOfY: "Einträge:",
        noData: "Nichts gefunden.",
        noDataWithFilter: "Es wurden keine Ergebnisse gefunden, die Ihren aktuellen Filtern entsprechen.",
        rowError_single: "{{message}}",
        rowError_multiple: "Reihe {{rowNumber}}: {{message}}",
        rowDetails: "Details zeigen",
        autocomplete: {
          showing: " {{current}} von {{total}} werden angezeigt ",
          showingMore: " {{from}} bis {{to}} von {{total}} Ergebnissen angezeigt. ",
          showAll: "All anzeigen",
          showMore: "Mehr anzeigen",
          addEntry: "Hinzufügen '{{value}}'",
          search: "Tippen zum suchen"
        },
        columnSelector: {
          buttonLabel: "Spalten editieren",
          dialogTitle: "Angezeigte Spalten editieren"
        },
        footer: {
          loading: "Laden",
          end: "Ende der Tabelle"
        }
      },
      form: {
        dirtyNavigationCheck: "Sind Sie sicher, dass Sie gehen wollen? Alle nicht gespeicherten Änderungen gehen verloren."
      },
      charts: {
        time: {
          labels: {
            day: "Tag",
            week: "Woche",
            month: "Monat",
            year: "Jahr"
          },
          dates: {
            monday: "Montag",
            tuesday: "Dienstag",
            wednesday: "Mittwoch",
            thursday: "Donnerstag",
            friday: "Freitag",
            saturday: "Samstag",
            sunday: "Sonntag",
            january: "Januar",
            february: "Februar",
            march: "März",
            april: "April",
            may: "Mai",
            june: "Juni",
            july: "Juli",
            august: "August",
            september: "September",
            october: "Oktober",
            november: "November",
            december: "Dezember"
          }
        },
        count: {
          total: "Gesamt"
        }
      },
      notification: {
        title: "Warnung",
        ok: "OK"
      },
      select: {
        noOptions: "Keine Optionen"
      },
      state: {
        error: "Fehler",
        ok: "OK",
        unknown: "Unbekannt",
        warning: "Warnung",
        loading: " "
      },
      validation: {
        alphaNumeric: "darf nur alphanumerische Zeichen enthalten",
        cidr: "muss eine gültige CIDR sein",
        dateOnAfter: "Datum muss am oder nach dem {{date}} liegen",
        dateOnBefore: "Datum muss am oder vor dem {{date}} liegen",
        int: "muss eine ganze Zahl sein",
        ip: "muss eine gültige IP-Adresse sein",
        privateIp: "muss eine gültige  PRIVATE IP-Adresse sein",
        ipCidr: "muss eine gültige IP-Adresse oder CIDR sein",
        privateIpCidr: "muss ein privates Netz sein",
        email: "muss eine gültige E-Mail-Adresse sein",
        domain: "muss eine gültige Domäne sein",
        wildcardDomain: "muss eine gültige Domain sein, Wildcards (*) sind erlaubt",
        lengthMax: "muss {{length}} oder weniger Zeichen enthalten",
        lengthMin: "muss contain {{length}} oder mehr Zeichen enthalten",
        passwordConfirm: "eingegebene Werte stimmen nicht überein",
        required: "erforderlich",
        duplicate: "darf keine Duplikate enthalten",
        valueMin: "muss {{min}} oder mehr sein",
        valueMax: "darf {{max}} oder weniger sein",
        url: "muss eine gültige URL sein"
      },
      delete: {
        title: "Löschen bestätigen ",
        message: "Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?",
        ok: "Ok",
        cancel: "Abbrechen"
      },
      action: {
        title: "Aktion bestätigen",
        message: "Sind Sie sicher, dass Sie diese Aktion durchführen wollen?",
        confirm: "Bestätigen",
        cancel: "Abbrechen"
      },
      save: {
        title: "Speichern bestätigen",
        message: "Sind Sie sicher, dass Sie diesen Eintrag speichern wollen"
      },
      unsupported: {
        browser: "Wir bitten vielmals um Entschuldigung, dieser Browser wird nicht unterstützt."
      },
      error: {
        message: "Wir bitten vielmals um Entschuldigung. Etwas ist schief gelaufen."
      },
      resourceNotFound: {
        subtitle: "Ressource nicht gefunden",
        content: "Wir bitten vielmals um Entschuldigung. Die von Ihnen gesuchte Ressource konnte nicht gefunden werden."
      },
      comingSoonPage: {
        iconHeader: "Demnächst verfügbar!",
        textHeader: "Diese Funktion befindet sich noch in der Entwicklung.",
        text: "bitte schauen Sie bald wieder rein !"
      },
      manageProfile: "Profil verwalten",
      ztnaSetupWizardGraphic: {
        directory: "Directory",
        agent: "Agent",
        devices: "Endgeräte",
        device: "Endgerät",
        user: "Benutzer",
        groups: "Gruppen",
        step1: "Zugangspunkt & Richtlinien",
        application: "App",
        edgeService: "Edge Service",
        basicInfrastructure: "Basisinfrastruktur"
      }
    },
    httpError: {
      400: "Ungültige Anfrage. Versuchen Sie es bitte später noch einmal.",
      401: "Sie sind nicht berechtigt, diese Anfrage durchzuführen. Melden Sie sich bitte an.",
      403: "Sie verfügen nicht über die Berechtigungen, um diese Anfrage durchzuführen.",
      404: "Das angeforderte Element wurde nicht gefunden.",
      500: "Unbekannter Fehler aufgetreten. Versuchen Sie es bitte später noch einmal.",
      501: "Unbekannte Anfrage.",
      502: "SecureEdge Service konnte nicht kontaktiert werden. Der Service befindet sich möglicherweise in Wartung. Versuchen Sie es bitte später noch einmal.",
      503: "SecureEdge Service konnte nicht kontaktiert werden. Der Service befindet sich möglicherweise in Wartung. Versuchen Sie es bitte später noch einmal.",
      504: "SecureEdge Service konnte nicht kontaktiert werden. Der Service befindet sich möglicherweise in Wartung. Versuchen Sie es bitte später noch einmal."
    }
  }
} as const;