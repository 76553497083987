import {useTranslation} from "react-i18next";
import {
    ConnectedFormPage,
    FormSection,
    PropGateway,
    TextInput,
    useGlobalParam,
    validatePrivateIpv4CIDR
} from "@cuda-react/core";
import {formatRequestCriteria} from "../../../../components/sourceAndDestination/formUtils";
import apiResources from "../../../../apiResources";
import {Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    helpText: {
        padding: theme.spacing(1, 1, 4, 3),
        color: theme.palette.text.secondary
    }
}));

export const ConnectorSettings = () => {
    const [translate] = useTranslation();
    const classes = useStyles();
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");

    return (
        <ConnectedFormPage
            title="tesseract.network.pageTitle"
            subtitle={["tesseract.network.settings", "tesseract.settings.tabs.sdwanConnectorNetwork.title"]}
            formatRequestData={formatRequestCriteria(virtualWanId)}
            resource={apiResources.sdwanConnectorNetwork}
            canReset
        >
            <FormSection title="tesseract.settings.tabs.sdwanConnectorNetwork.title" newStyle hideBorder>
                <PropGateway>
                    <Typography className={classes.helpText}>
                        {translate("tesseract.settings.tabs.sdwanConnectorNetwork.description")}
                    </Typography>
                </PropGateway>
                <TextInput
                    source="clientNetworkConfiguration.range"
                    label="tesseract.settings.tabs.sdwanConnectorNetwork.connectorIpRange"
                    validate={validatePrivateIpv4CIDR}
                    newStyle
                />
            </FormSection>
        </ConnectedFormPage>
    );
};

export default ConnectorSettings;