import {getArrayDataContent} from "../utils";
import {useCrudFetch} from "./CrudHooks";
import {useGlobalParam} from "./GlobalParamsHooks";
import {CrudTypes} from "../clients";
import {useEffect} from "react";
import {usePostCustomEvent} from "./CustomEventHooks";
import {AuthEvent, AuthEventKey, AuthEventType} from "../components";

/**
 * Representation of a single BCC Account
 */
export type Account = {
    id: string,
    name: string
};

/**
 * Representation of a single workspace
 */
export type Workspace = {
    description?: string,
    id?: string | number,
    name: string,
    uuid?: string
}

/**
 * Representation of a single BCC account enriched with associated workspace data
 */
export type AccountWorkspace = Account & {
    virtualWans?: Array<Workspace>
}

/**
 * Representation of a BCC User Account Details object, as returned by list accounts APIs.
 */
export type UserAccountDetails = {
    id: string,
    email: string,
    defaultAccount: Account,
    currentAccount: Account,
    allAccounts: Array<Account>
};

/**
 * Representation of a BCC User Account Details object, as returned by list accounts APIs.
 */
export type AccountList = Array<AccountWorkspace>;

/**
 * Array returned by the useAccounts hook.
 */
export type UseAccountResponse = [
    /** array of accounts associated with the current user. */
    AccountList,
    /** the currently active account. */
    Account,
    /** the default account as set on BCC. */
    Account,
    /** callback to swap to a target account. */
    (account: Account) => void,
    /** callback to request updated account data */
    () => void
];

const sortByName = (itemA: { name?: string }, itemB: { name?: string }) => {
    const itemAName = itemA?.name?.toLowerCase() || "";
    const itemBName = itemB?.name?.toLowerCase() || "";
    if (itemBName > itemAName) {
        return -1;
    }
    return itemAName > itemBName ? 1 : 0;
};

/**
 * Hook for performing Auth actions
 */
export const useAuthEvents = () => usePostCustomEvent<AuthEvent>(AuthEventKey);

/**
 * Hook for managing the current user's accounts.
 * @param listAccountsResource the CRUD resource to fetch the account list from. It is assumed this will return an array of accounts, and each account contains a "name" and "id" property.
 * @param updateInPlace if true, then the whole app is not reloaded, and instead the account is swapped via background fetch requests only. Otherwise the app is reloaded after changing accounts.
 * @return
 */
export const useAccounts = (listAccountsResource?: string, updateInPlace?: boolean): UseAccountResponse => {
    const [userData] = useGlobalParam("userData");
    const [auth0] = useGlobalParam("auth0");
    const [accountListResponse, , fetchAccountList] = useCrudFetch<AccountList>(CrudTypes.GET, listAccountsResource);
    const accountListData = getArrayDataContent(accountListResponse);
    const availableAccounts = (accountListData.length ? accountListData : (userData?.account_roles || []))
        .map((account: any): AccountWorkspace => ({
            id: account?.id || (account?.account_id + ""),
            name: account?.name || account?.account_name,
            virtualWans: account?.virtualWans ? account.virtualWans.sort(sortByName) : []
        })).sort(sortByName);
    const postAuthEvent = useAuthEvents();
    const currentAccount = availableAccounts.find((account: Account) => `${account.id}` === `${userData?.currentAccount}`) || {};
    const defaultAccount = availableAccounts.find((account: Account) => `${account.id}` === `${userData?.default_account}`) || {};
    const swapAccount = (account: Account) => postAuthEvent({
        type: AuthEventType.CHANGE_ACCOUNT,
        params: {accountId: account.id, updateInPlace: !!updateInPlace}
    });

    const refreshAccounts = () => {
        fetchAccountList();
    };

    useEffect(() => {
        auth0?.idToken && refreshAccounts();
    }, [userData?.currentAccount, auth0?.idToken]);

    return [availableAccounts, currentAccount, defaultAccount, swapAccount, refreshAccounts];
};