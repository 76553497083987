import {FormSection, TextInput, validateIpv4} from "@cuda-react/core";
import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";

export const DcAgentSection = ({className}: {className: string}) => {
    const [translate] = useTranslation();

    return (
        <FormSection title="tesseract.settings.tabs.dcAgent.title" newStyle>
            <Typography className={className}>
                {translate("tesseract.settings.tabs.dcAgent.description")}
            </Typography>
            <TextInput
                source="dcAgentIpAddress"
                label="tesseract.settings.tabs.dcAgent.ipAddress"
                validate={validateIpv4}
            />
        </FormSection>
    );
};

export default DcAgentSection;