import {
    BooleanInput, FormSection,
    TextInput,
    validateInt,
    validateMaxValueMemo,
    validateMinValueMemo
} from "@cuda-react/core";
import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";

export const SIPProxySection = ({className}: {className: string}) => {
    const [translate] = useTranslation();

    return (
        <FormSection title="tesseract.settings.tabs.SIPProxy.title" newStyle>
            <Typography className={className}>
                {translate("tesseract.settings.tabs.SIPProxy.cardDescription")}
            </Typography>
            <BooleanInput
                source="sipProxy.enabled"
                label="tesseract.settings.tabs.SIPProxy.sipProxySetting"
                additionalInfoLabel="tesseract.settings.tabs.SIPProxy.description.sipProxySetting"
            />
            <TextInput
                source="sipProxy.udpTimeout"
                label="tesseract.settings.tabs.SIPProxy.UdpTimeout"
                additionalInfoLabel="tesseract.settings.tabs.SIPProxy.description.UdpTimeout"
                validate={[validateInt, validateMaxValueMemo(300), validateMinValueMemo(30)]}
                disable={(value, data) => (data && (data.enabled))}
            />
        </FormSection>
    );
};

export default SIPProxySection;