import {
    BasicPage,
    Card
} from "@cuda-react/core";
import React from "react";
import {makeStyles} from "@mui/styles";
import SecurityUpdates from "./SecurityUpdates";
import FirmwareUpdates from "./FirmwareUpdates";

export const useStyles = makeStyles(() => ({
    root: {
        margin: 0,
        width: "100%"
    }
}));

export const UpdateWindows = () => {
    const classes = useStyles();

    return (
        <BasicPage
            title="tesseract.network.pageTitle"
            subtitle={["tesseract.network.settings", "tesseract.settings.tabs.updateWindows.title"]}
        >
            <Card className={classes.root}>
                <FirmwareUpdates/>
                <SecurityUpdates/>
            </Card>
        </BasicPage>
    );
};

export default UpdateWindows;