import React, {ReactNode, useEffect, useState} from "react";
import {
    Account,
    AccountWorkspace,
    useAccounts,
    useDeepCompareEffect,
    useGlobalParam,
    Workspace
} from "@cuda-react/core";
import apiResources from "../mainApiResources";
import {useTranslation} from "react-i18next";
import {TenantWorkspaceContext} from "../hooks/tenantWorkspaceHooks";

export interface TenantWorkspaceProviderProps {
    children: ReactNode;
}

const AppProvider = ({children}: TenantWorkspaceProviderProps): JSX.Element => {
    const [translate] = useTranslation();
    const noWorkspace = {name: translate("stratos.accounts.noWorkspace")};
    const [subscription = {}] = useGlobalParam("userData.subscription");
    const isSubscribed = subscription?.setUpComplete && subscription?.licensed && !subscription?.deprecated;
    const [availableAccounts, currentAccount, defaultAccount, swapAccount, refreshAccounts] = useAccounts(isSubscribed ? apiResources.accountListWithVirtualWans : undefined, true);
    const [workspaceId, setWorkspaceId] = useGlobalParam("filter.virtualWanId");
    const [, setWorkspaceUUID] = useGlobalParam("userData.currentWorkspace");
    const [nextWorkspace, setNextWorkspace] = useState<number | string | null>(null);
    const changeWorkspace = (account: Account, newWorkspaceId: string | number) => {
        if (account?.id !== currentAccount?.id) {
            swapAccount(account);
            setNextWorkspace(newWorkspaceId);
        } else {
            setWorkspaceId(newWorkspaceId);
        }
    };

    const [, setAccountTitle] = useGlobalParam("pageTitle.account");
    const workspaceChoices: Array<Workspace> = availableAccounts.find((account: AccountWorkspace) => currentAccount.id === account.id)?.virtualWans ?? [];
    const workspace: Workspace = workspaceChoices.find((choice: Workspace) => choice.id === workspaceId) || noWorkspace;

    useDeepCompareEffect(() => {
        setWorkspaceUUID(workspace.uuid);
    }, [workspace]);

    useDeepCompareEffect(() => {
        // If account was just swapped, now we can set the workspace id
        if (nextWorkspace) {
            setWorkspaceId(nextWorkspace);
            setNextWorkspace(null);
        } else {
            // if the workspaceId isn't one from the current account but the current account has workspaces, then update the id to one from the current account
            !workspace.id && workspaceChoices.length && setWorkspaceId(workspaceChoices[0].id);
        }
    }, [workspace, workspaceChoices, currentAccount?.id]);

    useDeepCompareEffect(() => {
        setAccountTitle(workspace?.id ? [currentAccount?.name, workspace.name] : currentAccount?.name);
    }, [currentAccount, workspace]);

    useEffect(() => {
        refreshAccounts();
    }, [isSubscribed]);

    return (
        <TenantWorkspaceContext.Provider value={{
            availableAccounts,
            changeWorkspace,
            currentAccount,
            defaultAccount,
            refreshAccounts,
            swapAccount,
            workspaceChoices,
            workspace
        }}>
            {children}
        </TenantWorkspaceContext.Provider>
    );
};

export default AppProvider;
