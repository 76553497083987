import {CudaReactLayout, usePreview, useGlobalParam} from "@cuda-react/core";
import SwapTenantIcon from "./components/SwapTenantOption/SwapTenantIcon";
import SwapTenantOption from "./components/SwapTenantOption/SwapTenantOption";
import CustomLogoutMenu from "./components/CustomLogoutMenu";
import CustomUnauthorizedPage from "./components/CustomUnauthorizedPage";
import Footer from "./components/Footer";
import {Route} from "react-router-dom";
import SubscriptionPage from "./components/subscription/SubscriptionPage";
import ExpiredPage from "./components/subscription/ExpiredPage";
import DeprecatedPage from "./components/subscription/DeprecatedPage";
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import CgwanModule from "@stratos/cgwan-ui";
import {useNavigationRoutes} from "./routing";
import {useUnleashContext} from '@unleash/proxy-client-react';
import WelcomePage from "./components/subscription/WelcomePage";
import GlobalStatusController from "./GlobalStatusController";
import mainApiResources from "./mainApiResources";
import LoadMfeChild from "./components/LoadMfeChild";

const AppContent = () => {
    const isReportsEnabled = usePreview('reports');
    const navigationEntries = useNavigationRoutes();
    const [mfeManifest, setMfeManifest] = useState<any>({});
    const [userId] = useGlobalParam("userData.currentAccount");
    const updateContext = useUnleashContext();

    // MFE manifest is an anonymous path, so we can use a plain fetch.
    // Using useCrud* methods at this high-level can cause unwanted behaviours such full app as refreshes
    useEffect(() => {
        if (isReportsEnabled) {
            fetch(mainApiResources.mfeManifest)
                .then((response) => response.json())
                .then((data) => setMfeManifest(data));
        }
    }, [isReportsEnabled]);

    useEffect(() => {
        // context is updated with userId
        userId && updateContext({userId});
    }, [userId]);

    return (
        <CudaReactLayout
            title="stratos.title"
            additionalNavbarOptions={useMemo(() => [
                {
                    name: "stratos.accounts.tenants",
                    icon: <SwapTenantIcon/>,
                    menu: <SwapTenantOption/>,
                },
            ], [])}
            customLogoutMenu={<CustomLogoutMenu/>}
            customUnauthorizedPage={CustomUnauthorizedPage}
            footerItems={<Footer/>}
            fullPageRoutes={useMemo(() => [
                <Route key="welcome" exact path="/welcome" component={WelcomePage}/>,
                <Route
                    key="post_purchase"
                    exact
                    path="/post_purchase"
                    component={SubscriptionPage}
                />,
                <Route key="expired" exact path="/expired" component={ExpiredPage}/>,
                <Route
                    key="deprecated"
                    exact
                    path="/deprecated"
                    component={DeprecatedPage}
                />,
            ], [])}
            navigation={navigationEntries}
            nonRouteChildren={<GlobalStatusController/>}
            requiresAuthentication
        >
            {isReportsEnabled && <Route path="/logs/reports">
                <LoadMfeChild loadout={mfeManifest?.microfrontends?.reporting}/>
            </Route>}
            <Route path="/" component={CgwanModule}/>
        </CudaReactLayout>
    );
};

export default AppContent;