import {
    ConnectedForm,
    DialogBody,
    TimeWindowInput,
    useMixpanel
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {get, set} from "lodash";
import React from 'react';
import {useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";
import {parseJson} from "../../../../utils/jsonUtils";

export const useStyles = makeStyles((theme) => ({
    durationText: {
        padding: theme.spacing(0, 4, 1)
    }
}));

interface SecurityUpdateDialogContentProps {
    onClose?: any;
    onSuccess: () => void;
}

const SecurityUpdateDialogContent: React.FC<SecurityUpdateDialogContentProps> = ({onSuccess, onClose}) => {
    const [translate] = useTranslation();
    const validateTime = (values: any, key: any, subKey: any, errors: any, errorMsg: any) => {
        if (!get(values, key + "." + subKey, "").match(/^([01][0-9]|2[0-3]):[0-5][0-9]$/)) {
            set(errors, key, translate(errorMsg));
        }
    };
    const registerAction = useMixpanel("Network Settings Security Updates", false);

    return (
        <DialogBody form onClose={onClose} title="tesseract.settings.tabs.updates.security.title">
            <ConnectedForm
                resource={apiResources.settings}
                formatRequestData={(data: any) => (
                    {
                        settings: {
                            security_updates: JSON.stringify(data.settings.security_updates || {})
                        }
                    }
                )}
                formatResourceData={(data: any) => (
                    {
                        settings: {
                            security_updates: data.settings?.security_updates && parseJson(data.settings.security_updates) || {
                                intervals: [{
                                    from: {
                                        day: "*",
                                        time: "00:00"
                                    }, to: {day: "*", time: "02:00"}
                                }]
                            }
                        }
                    }
                )}
                validate={(values: any) => {
                    const errors = {};
                    if (values && values.settings) {
                        validateTime(values, "settings.security_updates.intervals[0]", "to.time", errors, "tesseract.settings.tabs.updates.validation.invalidEndTime");
                        validateTime(values, "settings.security_updates.intervals[0]", "from.time", errors, "tesseract.settings.tabs.updates.validation.invalidStartTime");
                    }

                    return errors;
                }}
                params={{filter: {type: "virtualWan"}}}
                onSubmitSuccess={() => {
                    registerAction("Update Security Window");
                    onSuccess();
                }}
            >
                <TimeWindowInput
                    defaults={{from: {time: "00:00"}, to: {time: "02:00"}}}
                    isDaily
                    isRequired
                    label="tesseract.settings.tabs.updates.security.window"
                    source="settings.security_updates.intervals[0]"
                />
            </ConnectedForm>
        </DialogBody>
    );
};


export default SecurityUpdateDialogContent;