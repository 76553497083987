import {CrudTypes, useCrudFetch, useCrudSubscription, useGlobalParam} from "@cuda-react/core";
import apiResources from "../apiResources";
import {parseJson} from "../utils/jsonUtils";

export default (dashboardName: string, templates: any) => {
    const [{data: remoteStoredData}, , refreshDashboard] = useCrudSubscription(CrudTypes.GET, apiResources.customDashboard, {filter: {name: dashboardName}});
    const selectedDashboard = remoteStoredData?.content?.[0];
    const parsedRemoteStoredCustomDashboards = parseJson(selectedDashboard?.value, []);
    const remoteStoredCustomDashboards = parsedRemoteStoredCustomDashboards.map((dashValue: any) => {
        const findIndex: any = templates?.find((template: any) => JSON.stringify(template?.template) === JSON.stringify(dashValue?.template));
        return dashValue?.cards ?
            {name: dashValue?.name, cards: dashValue?.cards, template: dashValue?.template} :
            {name: dashValue?.name, cards: findIndex?.cards, template: dashValue?.template};
    });


    const idDashboard = selectedDashboard?.id;
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");

    const [, , performCreate] = useCrudFetch(CrudTypes.CREATE, apiResources.customDashboard);
    const [, , performUpdate] = useCrudFetch(CrudTypes.UPDATE, apiResources.customDashboard, {id: idDashboard});
    const handleDashboardFetch = (dashboards: any) => {
        const updatedDashboards = dashboards?.map((dashboard: any) => {
            const findDashboardTemplate: any = templates?.find((template: any) => JSON.stringify(template.template) === JSON.stringify(dashboard.template));

            return JSON.stringify(findDashboardTemplate?.cards) === JSON.stringify(dashboard?.cards) ?
                {name: dashboard?.name, template: dashboard?.template} :
                {name: dashboard?.name, cards: dashboard?.cards, template: dashboard?.template};
        });

        return (idDashboard ? performUpdate({
            data: {
                id: idDashboard,
                name: dashboardName,
                value: JSON.stringify(updatedDashboards),
                virtualWanId
            }
        }) : performCreate({
            data: {
                name: dashboardName,
                value: JSON.stringify(updatedDashboards),
                virtualWanId
            }
        })).then(() => refreshDashboard());
    };

    return {
        remoteStoredCustomDashboards,
        handleDashboardFetch,
        loadingDashboards: !remoteStoredData
    };
};