import {ProfileAvatar, ProfileAvatarProps} from "@barracuda-internal/bds-core";
import {useGlobalParam} from "../../../hooks";
import React from "react";

/**
 * A BDS ProfileAvatar that automatically generates the initials from the global parameters "userData.currentUserName", or "userData.currentUser".
 */
export const UserAvatar = (props: ProfileAvatarProps) => {
    const email = useGlobalParam("userData.currentUser")[0] || "Anonymous";
    const [name] = useGlobalParam("userData.name");
    const initials = name ? name.split(" ").reduce((initial: string, nameSegment: string) => initial + nameSegment[0].toUpperCase(), "") : email[0].toUpperCase();

    return (
        <ProfileAvatar {...props}>{initials}</ProfileAvatar>
    );
};

export default UserAvatar;