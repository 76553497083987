import React from "react";
import {useGlobalParam, useMixpanel} from "@cuda-react/core";
import {
    CardSizes,
    DashboardPage,
    ReportingDataCards,
    ReportingDataProvider,
    ZtnaDashboardDataCards
} from "@stratos/reporting";
import {useTranslation} from "react-i18next";
import useCustomDashboard from "../../../hooks/useCustomDashboard";
import {securityDashboardCards, webFilterDashboardCards, ztnaDashboardCards} from "../../dashboard/Dashboard";

import useReportingApiAndFilters from "../../../hooks/reportingDashboard/useReportingApiAndFilters";
import {GatewayDashboardCards} from "./cards";

interface GatewayDashboardProps {
    details: {
        id: string;
        name?: string;
        serials?: string[];
        uuid?: string;
        gatewayType?: "cloud" | "on-prem" | "managed";
        model?: string;
        applicationId?: string | number;
    };
    type: "gateways" | "sites";
}

export const gatewayDashboardCards = [
    {componentId: "GatewayTunnelLocationsCard", size: CardSizes.large},
    {componentId: "GatewaySummaryCard", size: CardSizes.small},
    {componentId: "FirmwareDetailsCard", size: CardSizes.small},
    {componentId: "SiteStatusCard", size: CardSizes.medium},
    {componentId: "IoTStatusCard", size: CardSizes.medium},
    {componentId: "VPNTunnelsGatewayCard", size: CardSizes.medium},
    {componentId: "GatewayLicensesCard", size: CardSizes.medium},
    {componentId: "FirmwareHotfixesCard", size: CardSizes.medium},
    {componentId: "ConfigurationUpdatesCard", size: CardSizes.small},
    {componentId: "ApplianceServicesCard", size: CardSizes.small},
    {componentId: "ApplianceInterfacesCard", size: CardSizes.medium},
    {componentId: "ApplianceResourcesCard", size: CardSizes.medium},
];

const GatewayDashboard = (props: GatewayDashboardProps) => {
    const {type, details} = props;
    const supportsReporting = details.gatewayType === "managed" || details.gatewayType === "on-prem";

    useMixpanel(
        "Gateways Dashboard",
        !!details.gatewayType,
        {model: details.model || "", type: details.gatewayType || ""}
    );

    const [translate] = useTranslation();
    const reportingApi = useReportingApiAndFilters({
        key: supportsReporting ? details.id && String(details.id) : "_UNSUPPORTED_",
        name: details.name || "",
        type: details.gatewayType || ""
    }, true);
    const templateDashboards = [{
        name: translate("tesseract.dashboard.status"),
        cards: gatewayDashboardCards,
        template: translate("tesseract.dashboard.status")
    },
    {
        name: translate("tesseract.dashboard.security"),
        cards: securityDashboardCards,
        template: translate("tesseract.dashboard.security")
    }, {
        name: translate("tesseract.dashboard.webFilter"),
        cards: webFilterDashboardCards,
        template: translate("tesseract.dashboard.webFilter")
    }, {
        name: translate("tesseract.dashboard.ztnaDashboard"),
        cards: ztnaDashboardCards,
        template: translate("tesseract.dashboard.ztnaDashboard")
    }, {
        name: translate("tesseract.dashboard.emptyTemplate"),
        cards: []
    }];

    const defaultDash = templateDashboards.slice(0, 4);
    const availableCards = {
        ...GatewayDashboardCards,
        ...ReportingDataCards,
        ...ZtnaDashboardDataCards
    };
    const {
        remoteStoredCustomDashboards,
        handleDashboardFetch,
        loadingDashboards
    } = useCustomDashboard("gatewayDashboard", templateDashboards);
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";

    return (
        <ReportingDataProvider
            data={reportingApi.data}
            loading={reportingApi.loading}
            register={reportingApi.register}
            unregister={reportingApi.unregister}
            addFilter={reportingApi.updateFilter}
        >
            <DashboardPage
                id="gatewayDashboard"
                availableCards={availableCards}
                defaultDashboards={defaultDash}
                templates={templateDashboards}
                filters={{filterButtons: reportingApi.filterButtons, activeFilters: reportingApi.activeFilters}}
                remoteStoredDashboards={isDemo ? undefined : remoteStoredCustomDashboards}
                loadingRemoteStoredDashboards={isDemo ? undefined : loadingDashboards}
                onDashboardsChange={isDemo ? undefined : handleDashboardFetch}
                maxDashboardsAmount={15}
                additionalProps={{details, type}}
            />
        </ReportingDataProvider>
    );
};
export default GatewayDashboard;