export default {
  translation: {
    cuda: {
      languages: {
        en: "English",
        fr: "Francais",
        ja: "日本語",
        de: "Deutsch",
        it: "Italiano"
      },
      auth: {
        logout: "ログアウト",
        checking: "認証を確認しています。",
        signInError: "サインイン中にエラーが発生しました。認証情報を確認して、もう一度お試しください。",
        switchAccount: "テナントの切り替え",
        unauthorized: "権限がありません。",
        unauthorizedMore: "このサービスにアクセスするための適切な権限がありません。管理者にお問い合わせください。",
        defaultAccount: "既定のテナント",
        profile: "プロファイル",
        signOut: "サインアウト"
      },
      supportAccess: {
        title: {
          enable: "サポートアクセスを有効にする。",
          disable: "サポートアクセスを無効にする。"
        },
        body: {
          enable: "サポートアクセスを有効にすると、Barracudaのサポート技術者がお客様に代わって構成変更を行うことができるようになります。Barracudaサポートによって行われた構成変更はすべて、監査ログで追跡することができます。",
          disable: "サポートアクセスを無効にします。Barracudaサポートによって行われたすべての構成変更は、監査ログに表示されます。"
        }
      },
      layout: {
        hideMenu: "メニュー非表示",
        showMenu: "メニュー表示"
      },
      buttons: {
        save: "保存",
        add: "追加",
        edit: "編集",
        delete: "削除",
        next: "次へ",
        finish: "完了",
        reset: "リセット",
        cancel: "キャンセル",
        back: "戻る",
        confirm: "確認",
        filters: {
          add: "フィルターの追加",
          clear: "フィルターのクリア"
        },
        downloadCsv: "CSVのダウンロード",
        return: "ホーム"
      },
      cards: {
        loading: "読み込み中…",
        noMessages: "現在利用可能なメッセージはありません。",
        noData: "結果が見つかりません。",
        noListData: "結果が見つかりません。",
        noTableData: "結果が見つかりません。",
        noEventData: "結果が見つかりません。",
        overview: "合計"
      },
      footer: {
        copyright: "Barracuda Networks, Inc. 無断転載禁止",
        privacyPolicy: "プライバシーポリシー",
        cookies: "Cookieの設定"
      },
      gdpr: {
        title: "EU Cookieの承認",
        body: "ユーザー エクスペリエンスを向上させるため、このサイトでは Cookie を使用しています。このサイトを引き続き使用することは、ユーザー様の同意とみなされます。。",
        link: "<1>プライバシーポリシー</1>を表示",
        accept: "同意"
      },
      inputs: {
        file: {
          browserUnsupported: "このブラウザーでは証明書のアップロードと検証はサポートされていません。最新の Firefox、Chrome、Edge、または Safariブラウザーをご利用ください。",
          uploadError: "ファイルのアップロード/読込み中にエラーが発生しました。もう一度お試しください。",
          buttonLabel: "ファイルのアップロード"
        },
        select: {
          empty: "なし"
        },
        autoSelect: {
          generated: "自動生成済み"
        },
        selectArray: {
          all: "すべて",
          typeToSearch: "入力して検索",
          showing: "{{total}}件の内 {{current}}件を表示中です。 ",
          unknownChipText: "不明"
        },
        selectSerial: {
          expandSectionButtonText: "シリアル/リンクコードを登録して一覧にないセキュアコネクタを追加します。",
          helpText: "これは Barracuda Networksからの確認メールに記載されています。",
          searchPlaceholder: "シリアルを検索",
          serial: "シリアル",
          linkingCode: "リンクコード",
          selectedSerials: "選択したシリアル"
        },
        timeWindow: {
          abbrevDaysOfWeek: {
            mon: "月",
            tue: "火",
            wed: "水",
            thu: "木",
            fri: "金",
            sat: "土",
            sun: "日"
          },
          daily: "毎日",
          from: "開始",
          to: "終了"
        },
        table: {
          noDataMessage: "追加された項目はありません。"
        },
        booleanCheckListInput: {
          categories: "カテゴリー",
          selectAll: "すべて選択",
          selectedItemsStatus: "({{allItems}}のうち{{checkedItems}}項目が選択済み)"
        },
        IconBrowser: {
          iconSelectorText: "定義済みアイコンを選ぶかカスタムアイコンをアップロードしてください。<0></0>対応ファイル形式：JPEG、PNG、GIF、SVG",
          useCustomText: "カスタムアイコン使用：",
          customApplicationText: "アプリケーションアイコン：",
          searchText: "アイコン検索：",
          placeholderText: "アイコン検索中...",
          noIcons: "アイコンが見つかりません。"
        }
      },
      table: {
        xOfY: "項目:",
        noData: "結果が見つかりませんでした。",
        noDataWithFilter: "現在のフィルターに一致する結果が見つかりません。",
        rowError_single: "{{message}}",
        rowError_multiple: "{{rowNumber}}行: {{message}}",
        rowDetails: "詳細の表示",
        autocomplete: {
          showing: "{{total}}件中{{current}}件の結果を表示中 ",
          showingMore: "{{total}}件中{{from}}から{{to}}までの結果を表示中 ",
          showAll: "すべて表示",
          showMore: "さらに表示",
          addEntry: "'{{value}}'を追加",
          search: "入力して検索"
        },
        columnSelector: {
          buttonLabel: "列を編集",
          dialogTitle: "表示可能な列の編集"
        },
        footer: {
          loading: "読み込んでいます。",
          end: "表の最後"
        }
      },
      form: {
        dirtyNavigationCheck: "終了してもよろしいですか？ 保存していない編集内容は失われます。"
      },
      charts: {
        time: {
          labels: {
            day: "日",
            week: "週",
            month: "月",
            year: "年"
          },
          dates: {
            monday: "月",
            tuesday: "火",
            wednesday: "水",
            thursday: "木",
            friday: "金",
            saturday: "土",
            sunday: "日",
            january: "1月",
            february: "2月",
            march: "3月",
            april: "4月",
            may: "5月",
            june: "6月",
            july: "7月",
            august: "8月",
            september: "9月",
            october: "10月",
            november: "11月",
            december: "12月"
          }
        },
        count: {
          total: "合計"
        }
      },
      notification: {
        title: "警告",
        ok: "OK"
      },
      select: {
        noOptions: "オプションなし"
      },
      state: {
        error: "エラー",
        ok: "OK",
        unknown: "不明",
        warning: "警告",
        loading: " "
      },
      validation: {
        alphaNumeric: "英数字のみ記入してください。",
        cidr: "有効な CIDRでなければなりません。",
        dateOnAfter: "日付は {{date}}以降でなければなりません。",
        dateOnBefore: "日付は {{date}}以前でなければなりません。",
        int: "整数でなければなりません。",
        ip: "有効な IPアドレスでなければなりません。",
        privateIp: "有効なプライベート IPアドレスでなければなりません。",
        ipCidr: "有効なIPアドレスまたは CIDRでなければなりません。",
        privateIpCidr: "プライベートネットワークでなければなりません。",
        email: "有効なメールアドレスでなければなりません。",
        domain: "有効なドメインでなければなりません。",
        wildcardDomain: "有効なドメインでなければならず、ワイルドカード(*)が利用可能です。",
        lengthMax: "{{length}}文字以下である必要があります。",
        lengthMin: "{{length}}文字以上である必要があります。",
        passwordConfirm: "入力された値が一致しません。",
        required: "必須",
        duplicate: "重複が含まれていてはなりません。",
        valueMin: "{{min}} 以上でなければなりません。",
        valueMax: "{{max}} 以下でなければなりません。",
        url: "は有効なURLでなければなりません"
      },
      delete: {
        title: "削除を確認してください。",
        message: "この項目を削除してもよろしいですか？",
        ok: "OK",
        cancel: "キャンセル"
      },
      action: {
        title: "アクションを確認",
        message: "このアクションを本当に実行してもよろしいですか？",
        confirm: "確認",
        cancel: "キャンセル"
      },
      save: {
        title: "保存の確認",
        message: "この項目を保存してもよろしいですか？"
      },
      unsupported: {
        browser: "申し訳ありませんが、このブラウザはサポートされていません。"
      },
      error: {
        message: "何らかの問題が発生しました。"
      },
      resourceNotFound: {
        subtitle: "リソースが見つかりません。",
        content: "お探しのリソースが見つかりませんでした。"
      },
      comingSoonPage: {
        iconHeader: "近日公開予定！",
        textHeader: "この機能はまだ開発中です。",
        text: "近日中に再度ご確認ください！"
      },
      manageProfile: "プロファイルの管理",
      ztnaSetupWizardGraphic: {
        directory: "ディレクトリ",
        agent: "エージェント",
        devices: "デバイス",
        device: "デバイス",
        user: "ユーザー",
        groups: "グループ",
        step1: "Point of Entry＆ポリシー",
        application: "アプリケーション",
        edgeService: "Edgeサービス",
        basicInfrastructure: "基本インフラストラクチャ"
      }
    },
    httpError: {
      400: "無効なリクエストです。しばらくしてからもう一度お試しください。",
      401: "このリクエストを実行する権限がありません。ログインしてください。",
      403: "このリクエストを実行する権限がありません。",
      404: "リクエストされたアイテムが見つかりません。",
      500: "不明なエラーが発生しました。しばらくしてからもう一度お試しください。",
      501: "不明なリクエストです。",
      502: "SecureEdge サービスに接続できません。サービスがメンテナンス中の可能性があります。しばらくしてからもう一度お試しください。",
      503: "SecureEdge サービスに接続できません。サービスがメンテナンス中の可能性があります。しばらくしてからもう一度お試しください。",
      504: "SecureEdge サービスに接続できません。サービスがメンテナンス中の可能性があります。しばらくしてからもう一度お試しください。"
    }
  }
} as const;