import {
    ChipArrayField, FormSection,
    TableInput,
    TextArrayInput,
    TextField,
    TextInput,
    validateArrayMemo,
    validateDomain,
    validateIpv4
} from "@cuda-react/core";
import {get} from "lodash";
import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";


const ForwardedDomainsSection = ({existingDomains, className}: {existingDomains: any, className: string}) => {
    const [translate] = useTranslation();

    return (
        <FormSection title="tesseract.settings.tabs.forwardedDomains.title" newStyle>
            <Typography className={className}>
                {translate("tesseract.settings.tabs.forwardedDomains.helpText")}
            </Typography>
            <TableInput
                source="dns_forwarding_zone.zones"
                optionValue="domain"
                formValidate={(values, initialValues) => {
                    const domain = get(values, "domain");
                    if (domain && domain !== get(initialValues, "domain") && existingDomains.includes(domain)) {
                        return {domain: translate("tesseract.settings.tabs.forwardedDomains.duplicateName")};
                    }
                }}
                labels={{
                    addTitle: "tesseract.settings.tabs.forwardedDomains.addDomain",
                    addButtonText: "tesseract.settings.tabs.forwardedDomains.addDomain",
                    editTitle: "tesseract.settings.tabs.forwardedDomains.editDomain",
                    deleteTitle: "tesseract.settings.tabs.forwardedDomains.deleteDomain"
                }}
                addButtonIcon
                label="tesseract.settings.tabs.forwardedDomains.title"
                smallSize
            >
                <TextField
                    source="domain"
                    label="tesseract.settings.tabs.forwardedDomains.domain"
                    inputComponent={TextInput}
                    inputProps={{isRequired: true, validate: [validateDomain]}}
                    width={296}
                />
                <ChipArrayField
                    source="forwarders"
                    label="tesseract.settings.tabs.forwardedDomains.forwarders"
                    inputComponent={TextArrayInput}
                    // @ts-ignore
                    inputProps={{validate: [validateArrayMemo(validateIpv4)], isRequired: true}}
                />
            </TableInput>
        </FormSection>
    );
};

export default ForwardedDomainsSection;