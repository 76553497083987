import * as React from "react";
import {CudaReactProvider} from "@cuda-react/core";
import StratosTheme from "./theme/StratosTheme";
import StratosMessages from "./i18n/StratosMessages";
import authClient from "./clients/authClient";
import {get} from "lodash";
import AppProvider from "./components/AppProvider";
import AppContent from "./AppContent";

const getUserIdentity = (globalParams: any | undefined) => get(globalParams, "userData.mode") === "demo" ? null : get(globalParams, "userData.currentUser");

const App = (): JSX.Element => {
    const hostname = window.location.hostname;
    const isProd = hostname.includes("barracudanetworks.com");

    return (
        <CudaReactProvider
            // @ts-ignore
            customTheme={StratosTheme}
            bccAuthentication
            customMessages={StratosMessages}
            authClient={authClient}
            mixpanelConfig={{
                token: isProd ? "1320f2411f85edb0c4282e94ff8abaf8" : "063c67642e59ec6bca5cb0da7c588b8b",
                identifier: getUserIdentity,
                appPrefix: (globalParams) => get(globalParams, "userData.mode") === "demo" ? "CGW-Demo" : "CGW"
            }}
            unleashConfig={{
                url: 'https://api.unleash.cudasvc.com/api/frontend/',
                clientKey: isProd ? "default:production.835574055e1eee1e75b7b1f890fac40dd0d84c1464d33f316fe170ac" : "default:development.e4621fe366a756a7c38868a87a2d21bd9d25db93a648745fb98246cb",
                refreshInterval: 300,
                appName: 'secure-edge',
                environment: isProd ? 'production' : 'development',
            }}
        >
            <AppProvider>
                <AppContent/>
            </AppProvider>
        </CudaReactProvider>
    );
};

export default App;