import React from "react";
import {LogoutMenu, useSupportAccess} from "@cuda-react/core";
import {LicenseIcon} from "@cuda-react/icons";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import apiResources from "../mainApiResources";

const CustomLogoutMenu = () => {
    const {supportAccessEnabled, onSupportAccessAccept} = useSupportAccess(apiResources.supportAccessEnabled);
    const [translate] = useTranslation();
    const history = useHistory();

    return (
        <LogoutMenu
            additionalChoices={[{name: translate("stratos.subscription.pam.menuTitle"), icon: <LicenseIcon/>, onClick: () => history.push("/subscription/pam")}]}
            supportAccessEnabled={supportAccessEnabled}
            onSupportAccessAccept={onSupportAccessAccept}
            showSupportAccess
        />
    );
};


export default CustomLogoutMenu;