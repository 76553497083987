import React from "react";
import {useMixpanel} from "@cuda-react/core";
import {CardSizes, DashboardPage, UserDashboardDataCards} from "@stratos/reporting";
import {useTranslation} from "react-i18next";
import useCustomDashboard from "../../../../hooks/useCustomDashboard";

export const UserDashboard = ({details}: any) => {
    useMixpanel("User Dashboard");
    const [translate] = useTranslation();
    const templateDashboards = [{
        name: translate("tesseract.dashboard.ztnaUserDashboard"),
        cards: [
            {componentId: "ZTNADeviceLocationCard", size: CardSizes.large},
            {componentId: "UserSummaryCard", size: CardSizes.small},
            {componentId: "UserDevicesCard", size: CardSizes.small},
        ],
        template: translate("tesseract.dashboard.ztnaUserDashboard")
    }];
    const {
        remoteStoredCustomDashboards,
        handleDashboardFetch,
        loadingDashboards
    } = useCustomDashboard("userDashboard", templateDashboards);

    return (
        <DashboardPage
            id="userDashboard"
            availableCards={{
                ...UserDashboardDataCards
            }}
            defaultDashboards={templateDashboards}
            templates={templateDashboards}
            additionalProps={details}
            oneTabDashboard
            remoteStoredDashboards={remoteStoredCustomDashboards}
            // @ts-ignore need to allow onDashboardsChange to accept a promise
            onDashboardsChange={handleDashboardFetch}
            loadingRemoteStoredDashboards={loadingDashboards}
        />
    );
};

export default UserDashboard;