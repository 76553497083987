import React, {useState} from "react";
import {
    useMixpanel,
    ConnectedFormPage
} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {parseJson} from "../../../../utils/jsonUtils";
import {get} from "lodash";
import {makeStyles} from "@mui/styles";
import DhcpServerSection from "./sections/DhcpServer";
import DcAgentSection from "./sections/DcAgent";
import ReportCreatorAclSection from "./sections/ReportCreatorAcl";
import ForwardedDomainsSection from "./sections/ForwardedDomains";
import SIPProxySection from "./sections/SIPProxy";

export const useStyles = makeStyles((theme) => ({
    helpText: {
        padding: theme.spacing(1, 1, 4, 3),
        color: theme.palette.text.secondary
    }
}));

export const GeneralNetworkSettings = () => {
    const [existingDomains, setExistingDomains] = useState<any>([]);
    const classes = useStyles();
    const registerAction = useMixpanel("Network Settings General", false);

    return (
        <ConnectedFormPage
            title="tesseract.network.pageTitle"
            subtitle={["tesseract.network.settings", "tesseract.settings.tabs.generalSettings.title"]}
            formatRequestData={(data: any) => ({
                settings: {
                    dhcp_relay: JSON.stringify({ips: [data["dhcpIpAddress"]]}),
                    dc_agent: JSON.stringify({ipAddress: data["dcAgentIpAddress"]}),
                    report_generator: JSON.stringify({acls: data?.reportCreator?.acls}),
                    dns_forwarding_zone: JSON.stringify(get(data, "dns_forwarding_zone", {})),
                    sip: JSON.stringify({
                                        enabled: data?.sipProxy.enabled ?? true,
                                        udpTimeout: parseInt(data?.sipProxy.udpTimeout, 10) || 60
                                    })
                }
            })}
            formatResourceData={(data: any) => {
                const sipData = JSON.parse(data?.settings?.sip || '{}');
                return {
                    dns_forwarding_zone: parseJson(get(data, "settings.dns_forwarding_zone")),
                    dhcpIpAddress: parseJson(data?.settings?.["dhcp_relay"])?.ips?.[0] || "",
                    dcAgentIpAddress: parseJson(data?.settings?.["dc_agent"])?.ipAddress || "",
                    reportCreator: parseJson(get(data, "settings.report_generator")),
                    sipProxy: {
                        enabled: sipData.enabled ?? true,
                        udpTimeout: sipData.udpTimeout ?? 60
                    }
                };
            }}
            formatError={(errors: any) => ({
                    acls: get(errors, "settings.report_generator"),
                    dhcpIpAddress: get(errors, "settings.dhcp_relay.ips"),
                    dcAgentIpAddress: get(errors, "settings.dc_agent.ipAddress"),
                    sipProxy: get(errors, "sip")
                })}
            onChange={(newValues: any) => {
                        setExistingDomains(get(newValues, "dns_forwarding_zone.zones", []).map((zone: { domain: any; }) => zone.domain));
                    }}
            resource={apiResources.settings}
            params={{filter: {type: "virtualWan"}}}
            onSubmitSuccess={() => {
                registerAction("Update");
            }}
            canReset
        >
            <ForwardedDomainsSection className={classes.helpText} existingDomains={existingDomains}/>
            <ReportCreatorAclSection className={classes.helpText}/>
            <DcAgentSection className={classes.helpText}/>
            <DhcpServerSection className={classes.helpText}/>
            <SIPProxySection className={classes.helpText}/>
        </ConnectedFormPage>
    );
};

export default GeneralNetworkSettings;