import React, {useState} from "react";
import SupportIcon from '@mui/icons-material/Support';
import LogoutIcon from '@mui/icons-material/Logout';
import LanguageIcon from "@mui/icons-material/Language";
import {useAuthEvents, useGlobalParam} from "../../../hooks";
import {useTranslation} from "react-i18next";
import {Check, ExpandLess, OpenInNew} from '@mui/icons-material';
import DialogBody from "../../dialog/DialogBody/DialogBody";
import Toolbar from "../../layout/Toolbar/Toolbar";
import {Button, Container, Dialog, ProfileMenu, ProfileMenuProps, Typography} from "@barracuda-internal/bds-core";
import {createStyles} from "@mui/styles";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import UserAvatar from "../UserAvatar/UserAvatar";
import {TFunction} from "i18next";
import classNames from "classnames";
import {useI18next} from "../../../i18n";
import UserIcon from "@barracuda-internal/bds-core/dist/Icons/Core/User";
import {AuthEventType} from "../../providers/AuthProvider/AuthProvider";

const styles = createStyles({
    body: {
        textAlign: "center",
        padding: 16,
        maxWidth: 600
    },
    userIcon: {},
    formControl: {
        width: 240,
        marginTop: -2
    },
    select: {
        width: 240
    },
    expandIcon: {
        transition: 'transform 0.25s ease-in-out',
    },
    expandIconExpanded: {
        transform: 'rotate(180deg)',
    },
});
const useStyles = makeOverrideableStyles("LogoutMenu", styles);

const translateMenuItems = (menuItems: ProfileMenuProps["menuItems"], translate: TFunction): ProfileMenuProps["menuItems"] => menuItems && menuItems.map((menuItem) => ({
    ...menuItem,
    name: translate(menuItem.name)
}));


export interface LogoutMenuProps extends StyledComponentProps<typeof styles> {
    /**
     * additional menu item choices. Added before the default logout option.
     *
     * See the "choices" prop of NavigationBarOptionsItem.
     * for the properties of the provided objects.
     */
    additionalChoices: ProfileMenuProps["menuItems"];
    /**
     * if true, the support access option is displayed
     */
    showSupportAccess?: boolean;
    /**
     * the current state of whether support access is currently enabled (so prompt to disable it).
     * Can be provided using the useSupportAccess hook.
     */
    supportAccessEnabled: boolean;
    /**
     * callback called when the support access dialog is accepted.
     * Can be provided using the useSupportAccess hook.
     * @param value
     */
    onSupportAccessAccept?: (value: boolean) => void;
}

/**
 * A standard username menu button with logout option. Support Enabling, and custom additional choices can also be added to the menu.
 *
 * The displayed username is collected from the [Global Param](/?path=/docs/cudareactapp-crud--page#global-params) "userData.currentUser".
 *
 * When logout is clicked, the [Auth Logout](/?path=/docs/cudareactapp-authentication--page) event is dispatched.
 */
export const LogoutMenu = (props: LogoutMenuProps) => {
    const {additionalChoices = [], showSupportAccess, supportAccessEnabled, onSupportAccessAccept} = props;
    const classes = useStyles(props);
    const [userData] = useGlobalParam("userData");
    const email = userData?.currentUser || "Anonymous";
    const name = userData?.name;
    const portalUrl = userData?.portalUrl || "login.barracudanetworks.com";
    const [translate] = useTranslation();
    const postAuthEvent = useAuthEvents();
    const [openConsent, setOpenConsent] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);
    const accessTitle = supportAccessEnabled ? "cuda.supportAccess.title.disable" : "cuda.supportAccess.title.enable";
    const accessBody = supportAccessEnabled ? translate("cuda.supportAccess.body.disable") : translate("cuda.supportAccess.body.enable");

    const {activeLanguage, availableLanguages, setActiveLanguage} = useI18next();

    return (
        <React.Fragment>
            <ProfileMenu
                icon={<UserAvatar/>}
                name={name}
                email={email}
                menuItems={translateMenuItems([
                    {
                        name: 'cuda.manageProfile',
                        onClick: (): void => {
                            window.open(portalUrl);
                        },
                        icon: <UserIcon/>,
                        endIcon: <OpenInNew/>,
                    },
                    ...(showSupportAccess ? [
                        {
                            name: accessTitle,
                            icon: <SupportIcon/>,
                            onClick: () => setOpenConsent(true)
                        }
                    ] : []),
                    ...additionalChoices,
                    {
                        icon: <LanguageIcon id="cuda-language-select"/>,
                        name: `cuda.languages.${activeLanguage}`,
                        onClick: () => setLanguageOpen(!languageOpen),
                        endIcon: <ExpandLess
                            className={classNames(
                                classes.expandIcon,
                                languageOpen && classes.expandIconExpanded,
                            )}
                        />
                    },
                    ...(languageOpen ? availableLanguages.map((language) => ({
                        icon: <div style={{width: 64}}/>,
                        name: `cuda.languages.${language}`,
                        onClick: () => setActiveLanguage?.(language),
                        endIcon: language === activeLanguage ? <Check/> : undefined
                    })) : []),
                    {
                        name: "cuda.auth.signOut",
                        icon: <LogoutIcon id="cuda-user-logout"/>,
                        onClick: () => postAuthEvent({type: AuthEventType.LOGOUT})
                    }
                ], translate)}
            />
            {showSupportAccess && (
                <Dialog
                    open={openConsent}
                    maxWidth={false}
                    onClose={() => (setOpenConsent(false))}
                >
                    <DialogBody title={accessTitle}>
                        <Container className={classes.body}>
                            <Typography variant="body1">
                                {accessBody}
                            </Typography>
                        </Container>
                    </DialogBody>
                    <Toolbar>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onSupportAccessAccept?.(!supportAccessEnabled);
                                setOpenConsent(false);
                            }}
                            size="small"
                        >
                            {translate("cuda.action.confirm")}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => (setOpenConsent(false))}
                        >
                            {translate("cuda.action.cancel")}
                        </Button>
                    </Toolbar>
                </Dialog>
            )}
        </React.Fragment>
    );
};

LogoutMenu.defaultProps = {
    additionalChoices: [],
    supportAccessEnabled: false
};

export default LogoutMenu;