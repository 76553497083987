import {IconButton, Typography} from "@barracuda-internal/bds-core";
import Tooltip from "@barracuda-internal/bds-core/dist/Tooltip";
import classNames from "classnames";
import React from "react";
import {Info} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {Trans, useTranslation} from "react-i18next";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {TFunction} from "i18next";

const getLabel = (translate: TFunction, label?: string, isRequired?: boolean): string => {
    let newLabel = translate(label || "");
    if (isRequired) {
        newLabel += " *";
    }
    return newLabel;
};

export interface BaseInputLabelProps {
    /**
     * an additional string to show on the bottom right side of an input field.
     */
    additionalInfoLabel?: string | { i18nKey: string, values: any },
    /**
     * if provided and fullWidth is not, width of the input will be automatically calculated.
     * If autoWidth, fillwidth and minimised props are not provided, width input will be set to: "calc(100% - 64px)"
     */
    autoWidth?: boolean,
    /**
     * the input children to wrap.
     */
    children?: React.ReactNode,
    /**
     * description of the field. If provided, the input will render a tootip with the description.
     */
    description?: React.ReactNode | string,
    /**
     * if provided, the input will get 100% width.
     * If autoWidth, fillwidth and minimised props are not provided, width input will be set to: "calc(100% - 64px)"
     */
    fullWidth?: boolean,
    /**
     * if true, input will get hidden.
     */
    hideInput?: boolean,
    /**
     * Sets whether to display the required '*' and use required validation
     */
    isRequired?: boolean,
    /**
     * if provided, maxHeight of the input will be set to "inherit"
     */
    isVarHeight?: boolean,
    /**
     * label of the input
     */
    label?: string,
    /**
     * this prop is used for different things,
     * if provided:
     *  - and fullWidth is not, width of the input, label and tooltip will be auto.
     *  - input padding & margin will be set to 0.
     *  - label padding will be set to theme.spacing(1.25, 2, 1.25, 0)
     */
    minimised?: boolean,
    /**
     * whether to display labels as per the new style i.e. bold
     */
    newStyle?: boolean,
    /**
     * shows the input nested under another input with indentation
     */
    nested?: boolean,
}

const styles = (theme: Theme) => createStyles<string, BaseInputLabelProps>({
    root: {
        textAlign: "left",
        padding: (props) => props.minimised ? 0 : props.nested ? theme.spacing(0, 8) : theme.spacing(0, 4),
        width: (props) => props.fullWidth && "100%" || ((props.minimised || props.autoWidth) ? "auto" : "calc(100% - 64px)"),
        display: "inline-block",
        minHeight: 40,
        margin: (props) => props.minimised ? 0 : theme.spacing(1.2, 0)
    },
    inputContainerVarHeight: {
        maxHeight: "inherit"
    },
    inputHelp: {
        display: "inline-block",
        float: "left",
        height: "auto",
        margin: "3px 12px 5px 0",
        width: (props) => props.minimised ? "auto" : 20
    },
    inputLabel: {
        display: "inline-block",
        float: "left",
        width: (props) => props.minimised ? "auto" : 210,
        padding: (props) => props.minimised ? theme.spacing(1.25, 2, 1.25, 0) : theme.spacing(1.25, 0),
        color: theme.palette.text.secondary,
        lineHeight: "19px",
    },
    newStyleInputLabel: {
        fontWeight: "bold",
    },
    inputAdditionalInfo: {
        maxWidth: "100%",
        fontSize: 12,
        lineHeight: "18px",
        marginTop: 4,
        marginLeft: (props) => props.minimised ? 0 : 245,
    },
    infoIcon: {
        fill: theme.palette.text.secondary
    },
    tooltip: {
        ...theme.typography.body2,
        // @ts-ignore Added by Cuda-react. Default (undefined), is fine
        color: theme.palette.text.tooltip,
    },
    hideInput: {
        display: "none"
    }
});

const useStyles = makeOverrideableStyles("InputLabel", styles);

export interface InputLabelProps extends BaseInputLabelProps, StyledComponentProps<typeof styles> {
}

/**
 * Renders an input field wrapped in a div and adds a tooltip (in case description props is provided) and a label to the input child.
 * Basically this component is used to give all the inputs the same aspect with a tooltip and a label for each of them.
 */
export const InputLabel = (props: InputLabelProps) => {
    const {
        additionalInfoLabel,
        children,
        description,
        isRequired,
        isVarHeight,
        hideInput,
        label,
        newStyle,
    } = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);

    return (
        <div
            className={classNames(
                classes.root,
                isVarHeight ? classes.inputContainerVarHeight : undefined,
                hideInput && classes.hideInput
            )}
            id="cuda-react-input"
        >
            <div className={classes.inputHelp}>
                {description && (
                    <Tooltip
                        title={typeof description === "string" ? translate(description) : description}
                        placement="top-end"
                        classes={{tooltip: classes.tooltip}}
                    >
                        <IconButton size="small">
                            <Info className={classes.infoIcon}/>
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            <Typography variant="body1"
                        className={classNames(classes.inputLabel, newStyle ? classes.newStyleInputLabel : undefined)}>
                <Trans i18nKey={getLabel(translate, label, isRequired)}/>
            </Typography>
            {children}
            {additionalInfoLabel ?
                <Typography variant="body1" className={classes.inputAdditionalInfo}>
                    {typeof additionalInfoLabel === "string" ?
                        <Trans i18nKey={additionalInfoLabel}>
                            <br/>
                        </Trans> :
                        <Trans i18nKey={additionalInfoLabel.i18nKey} values={additionalInfoLabel.values}>
                            <br/>
                        </Trans>
                    }
                </Typography> : null
            }
        </div>
    );
};

export default InputLabel;