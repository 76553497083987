import {Appliance} from "./useAppliances";
import {useEffect, useState} from "react";
import apiResources from "../../apiResources";
import {timeframes} from "./useFilterOptions";
import {CrudTypes, useCrudSubscription, useDeepCompareEffect} from "@cuda-react/core";

export const pollIntervals = {
    long: 300000,
    medium: 5000,
    short: 2000
};

export default (activeFilters: Record<string, any>, disabled: boolean, appliances: Appliance[], appliancesLoading: boolean) => {
    const [pollInterval, setPollInterval] = useState(pollIntervals.short);
    const [pollCount, setPollCount] = useState(0);
    const {applianceId: applianceIdWithType, timeframe, ...otherFilters} = activeFilters;

    const applianceId = String(applianceIdWithType).includes('/') ? String(applianceIdWithType)?.split('/')[0] : applianceIdWithType;

    // Use the correct API for the selected appliance type
    let applianceApi;
    const applianceType = String(applianceIdWithType).includes('/') ? String(applianceIdWithType)?.split('/')[1] : appliances.find((item) => item.key === applianceId)?.type;
    if (applianceType === "site") {
        applianceApi = apiResources.siteStatistics;
    } else if (applianceType === "on-prem") {
        applianceApi = apiResources.onpremStatistics;
    } else applianceApi = apiResources.managedGatewayStatistics;

    // Get the selected timeframe unit/duration, and remove filters without values set.
    const selectedTimeframe = timeframes.find((item) => item.key === timeframe) || timeframes[0];
    const validOtherFilters = Object.keys(otherFilters).reduce((result: any, key) => {
        const value = otherFilters[key];
        if (value !== undefined && value !== null && value !== "" && !(Array.isArray(value) && value.length === 0) && Object.keys(value).length > 0) {
            if (value?.key && value?.name) {
                result[key] = value?.key;
            } else {
                result[key] = value;
            }
        }
        return result;
    }, {});
    const filter = {
        unit: selectedTimeframe.unit,
        duration: selectedTimeframe.duration,
        ...validOtherFilters
    };

    const [{data}, loading, , clearState] = useCrudSubscription(
        CrudTypes.GET,
        (applianceId && applianceId !== "_NONE_" && applianceId !== "_UNSUPPORTED_" && appliances.length > 0 && !disabled) ? applianceApi : undefined,
        {
            id: applianceId,
            filter
        },
        {pollInterval}
    );

    useEffect(() => {
        if (!loading) {
            setPollCount((count) => count + 1);
        }
    }, [loading]);

    useDeepCompareEffect(() => {
        // Data has returned after 2 calls, so jump to long poll
        if ((!loading && data?.instanceId && pollCount >= 2) && pollInterval < pollIntervals.long) {
            setPollInterval(pollIntervals.long);
            // No data after 5 polls, start to backoff a bit
        } else if (pollCount > 5 && pollInterval < pollIntervals.medium) {
            setPollInterval(pollIntervals.medium);
            // No data after 10 polls, so backoff entirely to long poll
        } else if (pollCount > 10 && pollInterval < pollIntervals.long) {
            setPollInterval(pollIntervals.long);
        }
    }, [loading, data, pollCount]);

    useDeepCompareEffect(() => {
        clearState();
        setPollInterval(pollIntervals.short);
        setPollCount(0);
    }, [applianceId, filter]);

    return {
        data: data?.instanceId ? data : (appliances[0]?.key === "_UNSUPPORTED_" ? {instanceId: "_UNSUPPORTED_"} : {}),
        loading: appliancesLoading || loading || (appliances.length > 0 && appliances[0]?.key !== "_NONE_" && appliances[0]?.key !== "_UNSUPPORTED_" && !data?.instanceId && pollInterval === pollIntervals.short),
    };
};