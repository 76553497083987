import {AuthClient, AuthEventType} from "../components";
import {get, merge} from "lodash";
import {UserManager} from "oidc-client-ts";
import {AUTHENTICATION_FAILED} from "./crudClient";

export type UserManagerData = {
    clientId: string,
    domain: string
};

let userManager: UserManager | undefined;
export const clearUserManager = () => userManager = undefined;

const getUserManager = async (userManagerSettings: UserManagerData) => {
    if (userManager?.settings?.client_id === userManagerSettings?.clientId) {
        return userManager;
    }

    userManager = new UserManager({
        authority: userManagerSettings?.domain,
        client_id: userManagerSettings?.clientId,
        redirect_uri: `https://${location.hostname}${(location.port && location.port !== "443" ? ":" + location.port : "")}/#/login`,
        response_type: "code",
        scope: "openid basic profile email session account_roles",
    });
    return userManager;
};

const getUserManagerSettings = async (globalParams?: any, force?: boolean) => {
    if (force || (!get(globalParams, "auth0.clientId") && !get(globalParams, "auth0.domain") && !get(globalParams, "auth0.demoMode") && !get(globalParams, "auth0.demoJwt"))) {
        return await fetch("/api/v1/webui/authenticate").then((response) => response.json()).then((data) => ({
            ...data,
            demoJwt: data?.demoMode ? "123456789" : "",
            clientId: data?.["se-client-id"],
            domain: data?.["se-auth-server"]
        })).catch(() => ({
            clientId: "2FQRLQ09IrDp07QzzngX5M9IoeedTKZAqB38BdQt3kT8xgx1Si4svuHE5IdeqfQ",
            domain: "https://auth.barracudanetworks.com"
        }));
    }
    return {
        clientId: get(globalParams, "auth0.clientId"),
        domain: get(globalParams, "auth0.domain"),
        demoMode: get(globalParams, "auth0.demoMode"),
        demoJwt: get(globalParams, "auth0.demoJwt")
    };
};

const auth0login = (globalParams?: any) => getUserManagerSettings(globalParams, true)
    .then((userManagerSettings) => getUserManager(userManagerSettings).then((userManager) => userManager.signinRedirect({
            redirectTarget: "top"
        }).then(() => ({
            auth0: {
                ...userManagerSettings,
                idToken: null,
                accessToken: null,
                data: null
            }
        }))
    ));

const auth0GetTokens = async (userManager: UserManager, params?: any) => {
    if (params?.state || params?.code) {
        await userManager.signinRedirectCallback(`https://${location.host}?${Object.keys(params).map((key) => `${key}=${params[key]}`).join("&")}`);
    }
    const user = await userManager.getUser();
    if (user?.id_token && !user?.expired) {
        return {
            idToken: user?.id_token,
            accessToken: user?.access_token,
            data: user?.profile
        };
    }
    await userManager.removeUser();
    return {idToken: undefined, accessToken: undefined, data: null};
};

export const sessionStatuses = {
    authenticating: false
};

const authLogin = ((params: any, globalParams?: any) => {
    if (sessionStatuses.authenticating) {
        return Promise.reject();
    }
    sessionStatuses.authenticating = true;
    const mergedParams = merge({}, globalParams, params);

    return getUserManagerSettings(globalParams).then((userManagerSettings) => {
        if (userManagerSettings?.demoMode) {
            return {
                redirect: get(mergedParams, "previousOrigin", get(mergedParams, "origin")) || "/",
                auth0: {
                    ...userManagerSettings,
                    idToken: userManagerSettings.demoJwt
                },
                userData: {
                    mode: "demo",
                    account_roles: [
                        {
                            account_id: 12345678,
                            account_name: "Demo Enterprises Inc",
                        },
                        {
                            account_id: 87654321,
                            account_name: "Demo Deutschland Gmbh",
                        }
                    ],
                    currentAccount: "12345678",
                    currentUser: "guest@barracuda.com",
                    email: "guest@barracuda.com",
                    name: "Guest"
                }
            };
        }
        return getUserManager(userManagerSettings).then(async (userManager) => {
            const {idToken, accessToken, data} = await auth0GetTokens(userManager, params).catch(() => ({
                idToken: undefined,
                accessToken: undefined,
                data: null,
                ...userManagerSettings
            }));

            if (!idToken) {
                return auth0login(globalParams);
            }

            // Sert to default account if no curren account set, or current account is not listed in account roles.
            let currentAccount = globalParams?.userData?.currentAccount;
            if (!currentAccount || !data?.account_roles?.some((account: any) => `${account.account_id}` === `${currentAccount}`)) {
                currentAccount = `${data?.default_account}`;
            }
            return {
                redirect: get(mergedParams, "previousOrigin", get(mergedParams, "origin")) || "/",
                // TODO: Will need to add STS call here
                userData: {
                    ...data,
                    currentAccount,
                    currentUser: data?.email
                },
                auth0: {
                    idToken,
                    accessToken,
                    data,
                    ...userManagerSettings
                }
            };
        });
    }).finally(() => {
        sessionStatuses.authenticating = false;
    });
});

const authChangeAccount = ((params: any, globalParams?: any) => {
    //TODO: Will need to add STS call here, but otherwise currently nothing else to do
    return Promise.resolve({
        userData: {
            ...globalParams?.userData,
            currentAccount: get(params, "accountId")
        },
        redirect: !params?.updateInPlace ? "/login" : undefined
    });
});

const auth0logout = (userManager: UserManager, userManagerSettings: UserManagerData) =>
    userManager.removeUser()
    .then(() => fetch(`${userManagerSettings.domain.replace("auth.bcc.", "login.")}/auth/logout/`, {mode: "no-cors", credentials: "include"}));

const authLogout = (_params: any, globalParams?: any) => getUserManagerSettings(globalParams).then((userManagerSettings) => {
    if (userManagerSettings?.demoMode) {
        return Promise.reject({redirect: "/login"});
    }
    return getUserManager(userManagerSettings)
        .then((userManager) => auth0logout(userManager, userManagerSettings))
        .then(() => ({
            redirect: "/login",
            auth0: {...(globalParams?.auth0 || {}), idToken: null, accessToken: null, data: null}
        }));
});

export const authError = (params?: any, globalParams?: any) => {
    if (params?.status === 308) {
        return Promise.reject();
    } else if (params?.status === 403) {
        return Promise.reject({redirect: "/unauthorized"});
    } else if (params === AUTHENTICATION_FAILED || params.status === 401) {
        // Auth failed, so remove any active tokens and redirect to login
        return Promise.reject({redirect: "/login", auth0: {...(globalParams?.auth0 || {}), idToken: null, accessToken: null, data: null}});
    }

    return Promise.resolve();
};

const authCheck = (authenticated?: any, globalParams?: any) => {
    if (!(get(globalParams, "auth0.idToken")) || (authenticated && typeof authenticated === "function" && !authenticated(globalParams))) {
        return Promise.reject();
    }
    return Promise.resolve({});
};

export default ((type: AuthEventType, params?: any, globalParams?: any) => {
    switch (type) {
        case AuthEventType.LOGIN:
            return authLogin(params, globalParams);
        case AuthEventType.CHANGE_ACCOUNT:
            return authChangeAccount(params, globalParams);
        case AuthEventType.LOGOUT:
            return authLogout(params, globalParams);
        case AuthEventType.ERROR:
            return authError(params, globalParams);
        case AuthEventType.CHECK:
            return authCheck(params, globalParams);
        default:
            return Promise.reject("Unknown Method");
    }
}) as AuthClient;