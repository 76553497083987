import {
    ConnectedForm,
    DateTimeInput,
    DialogBody,
    ErrorDisplay,
    getArrayDataContent,
    SearchableSelectInput,
    useCrudProps,
    useGlobalParam
} from "@cuda-react/core";
import {Grid, Typography} from "@barracuda-internal/bds-core";
import {FormHelperText} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {addHours, formatDuration, intervalToDuration, isBefore, parseISO, subHours} from "date-fns";
import {concat} from "lodash";
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {updateWindowValidation} from "../updateWindowFormValidation";
import {GatewayIcon, SiteIcon} from "@cuda-react/icons";
import {convertApplianceId} from "../FirmwareUpdates";
import apiResources from "../../../../../apiResources";

export const useStyles = makeStyles((theme) => ({
    durationText: {
        padding: theme.spacing(0, 4, 1)
    },
    textContainer: {
        textAlign: "left",
        paddingBottom: 12,
        margin: "20px 16px"
    },
}));

interface Data {
    configurationId?: number;
    configurationType?: "site" | "gateway" | string;
    id?: number;
}

interface Appliance {
    key?: number | string;
    name?: string;
    type?: "site" | "gateway" | string;
}

interface UpdateDialogContentProps {
    data?: Data;
    availableUpdates: Record<string, any>;
    defaultUpdateWindow?: boolean;
    appliances?: Appliance[];
    onClose?: any;
    onSuccess?: () => void;
    title?: string;
}

const UpdateDialogContent: React.FC<UpdateDialogContentProps> = ({
                                                                     data,
                                                                     appliances,
                                                                     onClose,
                                                                     title,
                                                                     defaultUpdateWindow,
                                                                     onSuccess,
                                                                     availableUpdates
                                                                 }) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const [vwanId] = useGlobalParam("filter.virtualWanId");
    const gatewayNames = getArrayDataContent(useCrudProps(apiResources.gatewayNames)[0]?.data);
    const siteNames = getArrayDataContent(useCrudProps(apiResources.siteNames)[0]?.data);

    const [timeWindow, setTimeWindow] = useState();
    const [currentValues, setCurrentValues] = useState<any>({});
    const [minDate] = useState(subHours(new Date(), 26));

    // if we're editing an update window, add in the current gateway as a dropdown option
    const currentAppliance = data && (
        data.configurationType === "gateway"
            ? gatewayNames.find((gateway) => gateway.key === data.configurationId)
            : siteNames.find((site) => site.key === data.configurationId)
    );
    const availableAppliances = currentAppliance
        ? concat(convertApplianceId(data.configurationType)(currentAppliance), appliances)
        : appliances;

    return (
        <DialogBody form onClose={onClose} title={title}>
            <ConnectedForm
                id={data?.id}
                resource={apiResources.update}
                params={defaultUpdateWindow ? {filter: {allConfigurations: true}} : undefined}
                initialValues={data?.id ? data : undefined}
                create={!data?.id}
                formatRequestData={({applianceId, ...values}) => {
                    const [configurationType, configurationId] = applianceId?.split(":") || [null, null];
                    return {
                        ...values,
                        configurationType,
                        configurationId,
                        virtualWanId: vwanId
                    };
                }}
                formatResourceData={({configurationType, configurationId, ...values}) => ({
                    ...values,
                    applianceId: configurationType ? `${configurationType}:${configurationId}` : undefined
                })}
                onChange={(values) => {
                    setCurrentValues(values);

                    const start = new Date(values.from);
                    const end = new Date(values.to);
                    const interval = values.from && values.to && isBefore(start, end) && intervalToDuration({
                        start,
                        end
                    });
                    setTimeWindow(interval);
                }}
                validate={updateWindowValidation(availableUpdates.mustInstallBy, translate)}
                onSubmitSuccess={() => {
                    onSuccess && onSuccess();
                    onClose();
                }}
                onCancel={onClose}
            >
                {appliances ?
                    <SearchableSelectInput
                        source="applianceId"
                        label="tesseract.settings.tabs.updates.firmware.fields.appliance"
                        choices={availableAppliances}
                        iconMap={{
                            gateway: <GatewayIcon/>,
                            site: <SiteIcon/>
                        }}
                        optionIcon="type"
                        clearOnFocus
                    /> : null
                }
                <Grid item xs={12} className={classes.textContainer}>
                    <Typography variant="body1">
                        {translate("tesseract.settings.tabs.updates.firmware.fields.timeWindowDescription")}
                    </Typography>
                </Grid>
                <DateTimeInput
                    source="from"
                    label="tesseract.settings.tabs.updates.firmware.fields.from"
                    isRequired
                    pickerProps={{
                        minDate,
                        maxDate: availableUpdates.mustInstallBy,
                        initialFocusedDate: currentValues.to && subHours(parseISO(currentValues.to), 3)
                    }}
                />
                <DateTimeInput
                    source="to"
                    label="tesseract.settings.tabs.updates.firmware.fields.to"
                    isRequired
                    pickerProps={{
                        minDate,
                        maxDate: availableUpdates.mustInstallBy,
                        initialFocusedDate: currentValues.from && addHours(parseISO(currentValues.from), 3)
                    }}
                />
                <ErrorDisplay source="virtualWan"/>
                {timeWindow ? (
                    <FormHelperText className={classes.durationText}>
                        {translate("tesseract.settings.tabs.updates.firmware.fields.windowLength", {duration: formatDuration(timeWindow)})}
                    </FormHelperText>
                ) : null}
            </ConnectedForm>
        </DialogBody>
    );
};


export default UpdateDialogContent;