import React from "react";
import {
    AutoSelectInput,
    BooleanInput,
    ChipArrayField,
    FormSection,
    getArrayDataContent,
    InputLabel,
    MultiInput,
    SelectInput,
    TextArrayInput,
    TextInput,
    useCrudProps, usePreview,
    validateIpv4,
    validateIpv4CIDR
} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";
import {useSiteInputValidations} from "../../../../hooks/createEditSiteHooks";
import {useWatch} from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        fontSize: 16,
    },
    completeMessage: {
        margin: theme.spacing(3)
    }
}));

export const SourceDestInput = ({gateways, sites}: { gateways: any[], sites: any[] }) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const [selectedUuid, selectedType = "gateway", selectedWanKey] = useWatch({name: ["local.uuid", "local.type", "local.wan"]});
    const cloudGatewaySelected = gateways.find(({uuid}) => selectedUuid === uuid)?.cloud;
    const inputValidations = useSiteInputValidations();
    const sourceTypeData = [
        {
            key: "gateway",
            name: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.sourceTypeData.gateway"
        },
        {
            key: "site",
            name: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.sourceTypeData.site"
        }
    ];
    const wanChoices = (selectedType === "gateway" ? gateways : sites).find((item) => item?.uuid === selectedUuid)?.wans || [];
    const gatewayData = getArrayDataContent(useCrudProps(cloudGatewaySelected && apiResources.gateways, {filter: {uuid: selectedUuid}})[0]?.data)[0];
    const selectedWan = Array.isArray(wanChoices) && wanChoices.find((wan) => wan.key === selectedWanKey && wan.address);
    const wanIpChoices = selectedWan ? [
        {key: selectedWan?.address, name: selectedWan?.address, type: translate("tesseract.integration.ipSecV2.dialog.sourceDestSection.source.primaryAddress")},
        ...(selectedWan?.addresses || []).map((ip: string) => ({key: ip, name: ip, type: translate("tesseract.integration.ipSecV2.dialog.sourceDestSection.source.additionalAddresses")}))
    ] : [];
    const IKEv2Preview = usePreview("ikev2dns");

    return (
        <React.Fragment>
            <Typography variant="body1" className={classes.completeMessage}>
                {translate("tesseract.integration.ipSecV2.dialog.sourceDestSection.descriptionMessage")}
            </Typography>
            <BooleanInput
                source="bgp"
                label="tesseract.integration.ipSecV2.dialog.sourceDestSection.enableBgp"
                description="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.enableBgp"
            />
            <FormSection title="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.title">
                <SelectInput
                    label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.type"
                    source="local.type"
                    choices={sourceTypeData}
                    isRequired
                />
                <SelectInput
                    label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.peer"
                    source="local.uuid"
                    choices={selectedType === "site" ? sites : gateways}
                    optionValue="uuid"
                    isRequired
                />
                {!cloudGatewaySelected && (
                    <SelectInput
                        label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.wanInterface"
                        source="local.wan"
                        choices={wanChoices}
                    />
                )}
                {!cloudGatewaySelected && selectedWan && (
                    <SelectInput
                        label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.localWans"
                        source="local.address"
                        choices={wanIpChoices}
                        optionCategory={wanIpChoices[0]?.type ? "type" : undefined}
                    />
                )}
                {cloudGatewaySelected && (
                    <InputLabel
                        label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.ipAddresses"
                        description="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.ipAddresses"
                    >
                        <ChipArrayField
                            source="connectionStatus.connectionStates"
                            data={gatewayData}
                            render={(value) => value?.id}
                        />
                    </InputLabel>
                )}
                <TextInput
                    label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.localId"
                    description="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.localId"
                    source="local.id"
                    validate={inputValidations.validateAlphaNumericDotsColonsDash(255)}
                />
                {IKEv2Preview && (
                <TextInput
                    source="local.vip"
                    label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.dnsVip"
                    description="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.dnsVip"
                    validate={inputValidations.validateIp}
                    hide={(value, data) => (data?.bgp)}
                />)}
                <TextArrayInput
                    source="local.networks"
                    label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.networkAddresses"
                    description="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.networkAddresses"
                    validate={inputValidations.validateCidrArray}
                    isRequired
                    hide={(value, data) => (data?.bgp)}
                />
                <TextInput
                    source="local.bgpAddress"
                    label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.networkAddressBgp"
                    description="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.networkAddressBgp"
                    isRequired
                    validate={validateIpv4CIDR}
                    hide={(value, data) => !(data?.bgp)}
                />
                <AutoSelectInput
                    key="local.asn"
                    source="local.asn"
                    label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.asnBgp"
                    description="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.asnBgp"
                    resource={apiResources.availableAsns}
                    validate={inputValidations.validateAsnIpsec}
                    hide={(value, data) => !(data?.bgp)}
                    allowShuffle
                    allowManualInput
                    isRequired
                />
            </FormSection>
            <FormSection title="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.title">
                <TextInput
                    label="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.remoteGateway"
                    description="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.remoteGateway"
                    source="remotes[0].gateway"
                    hide={(value, data) => (data?.bgp)}
                    isRequired
                />
                <TextInput
                    label="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.remoteId"
                    description="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.remoteId"
                    source="remotes[0].id"
                    validate={inputValidations.validateAlphaNumericDotsColonsDash(255)}
                    hide={(value, data) => (data?.bgp)}
                />
                <TextArrayInput
                    source="remotes[0].networks"
                    label="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.networkAddresses"
                    description="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.networkAddresses"
                    validate={inputValidations.validateCidrArray}
                    isRequired
                    hide={(value, data) => (data?.bgp)}
                />
                <MultiInput
                    maxInputs={2}
                    source="remotes"
                    hide={(value: any, data: { bgp: boolean; }) => !(data?.bgp)}
                    columnStyle
                    columnStyleItemLabelSource="gateway"
                    columnStyleItemLabel="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.multiInputTitle"
                    columnStyleTitleLabel="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.multiInputTitleLabel"
                >
                    <TextInput
                        label="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.remoteGateway"
                        description="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.remoteGateway"
                        source="gateway"
                        isRequired
                    />
                    <TextInput
                        label="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.remoteId"
                        description="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.remoteId"
                        source="id"
                        validate={inputValidations.validateAlphaNumericDotsColonsDash(255)}
                    />
                    <TextInput
                        source="bgpAddress"
                        label="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.networkAddressBgp"
                        description="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.networkAddressBgp"
                        validate={validateIpv4}
                        isRequired
                    />
                    <TextInput
                        key="asn"
                        source="asn"
                        label="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.asnBgp"
                        description="tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.asnBgp"
                        inputProps={{type: "number"}}
                        validate={inputValidations.validateAsnAny}
                        isRequired
                    />
                </MultiInput>
            </FormSection>
        </React.Fragment>
    );
};

export default SourceDestInput;