import React from "react";
import {
    BooleanInput,
    getArrayDataContent,
    ipv4Regex,
    PasswordConfirmInput,
    SelectInput,
    TableInput,
    TextArrayInput,
    TextInput,
    TextField,
    useCrudProps,
    usePreview,
    validateArray,
    validateDomain,
    Validator
} from "@cuda-react/core";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import apiResources from "../../../../../apiResources";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import CreateEditButtonDialog from "../../../../CreateEditButtonDialog";
import DownloadBootstrapConfigDialog from "./DownloadBootstrapConfigDialog";

export const useStyles = makeStyles((theme) => ({
    bootstrapConfigButton: {
        margin: theme.spacing(2, 2, 2, 4)
    }
}));

interface AdvancedSettingsTabProps {
    applianceType?: "site" | "gateway",
    series?: string,
    serials?: any,
    gatewayType?: "cloud" | "on-prem" | "managed"
}

export const AdvancedSettingsTab: React.FC<AdvancedSettingsTabProps> = ({applianceType, series, serials, gatewayType}) => {
    const timezonesData = getArrayDataContent(useCrudProps(apiResources.siteTimezones)[0]?.data);
    const inputValidations = useSiteInputValidations();
    const [translate] = useTranslation();
    const classes = useStyles();
    const isSc = series === "S";
    const isProxyConnect = usePreview("proxyconnect");
    const isReporting = usePreview("reports");

    const ipv4SocketValidator: Validator = (ipv4Socket: string) => {
        const [ipv4Address, socket] = ipv4Socket.split(":");
        const isValidIpv4Address = ipv4Regex.test(ipv4Address);
        const isValidSocket = parseInt(socket) > 0 && parseInt(socket) <= 65535;
        return isValidIpv4Address && isValidSocket ? undefined : translate("tesseract.appliances.settings.advanced.validation.proxyConnect");
    };

    return (
        <React.Fragment>
            <PasswordConfirmInput
                source="password"
                label="tesseract.appliances.settings.advanced.rootPassword"
                description={translate("tesseract.appliances.settings.advanced.descriptions.rootPassword", {context: applianceType})}
                confirmLabel="tesseract.appliances.settings.advanced.rootPasswordConfirmation"
                validate={inputValidations.validatePassword}
                //@ts-ignore not sure why this one complains.
                autocomplete="new-password"
            />
            {!isSc ? (
                <SelectInput
                    choices={timezonesData}
                    source="timezone"
                    label="tesseract.appliances.settings.advanced.updateWindowTZ"
                    description={translate("tesseract.appliances.settings.advanced.descriptions.updateWindowTZ", {context: applianceType})}
                    optionValue="key"
                    isRequired
                />
            ) : null}
            <TextInput
                source="primaryDns"
                label="tesseract.appliances.settings.advanced.primaryDns"
                description="tesseract.appliances.settings.advanced.descriptions.primaryDns"
                validate={inputValidations.validateIp}
            />
            <TextInput
                source="secondaryDns"
                label="tesseract.appliances.settings.advanced.secondaryDns"
                description="tesseract.appliances.settings.advanced.descriptions.secondaryDns"
                validate={inputValidations.validateIp}
                disable={(value, data) => !(data && (data.primaryDns || value))}
            />
            {!isSc ?
            <TableInput
                source="dnsInterception"
                optionValue="domain"
                label="tesseract.appliances.settings.advanced.dnsInterception"
                description="tesseract.appliances.settings.advanced.descriptions.dnsInterception"
                labels={{
                    addTitle: "tesseract.appliances.settings.advanced.dnsInterceptionAddTitle",
                    editTitle: "tesseract.appliances.settings.advanced.dnsInterceptionEditTitle",
                    deleteTitle: "tesseract.appliances.settings.advanced.dnsInterceptionDeleteTitle",
                    deleteMessage: "tesseract.appliances.settings.advanced.dnsInterceptionDeleteMessage"
                }}
                addButtonIcon
                smallSize
            >
                <TextField
                    source="domain"
                    label="tesseract.appliances.settings.advanced.domain"
                    inputComponent={TextInput}
                    inputProps={{
                        validate: validateDomain,
                        isRequired: true
                    }}
                    />
                <TextField
                    source="ipAddress"
                    label="tesseract.appliances.settings.advanced.ipAddress"
                    inputComponent={TextInput}
                    inputProps={{
                        validate: inputValidations.validateIp,
                        isRequired: true
                    }}
                />
            </TableInput> : null}
            {applianceType === "gateway" && gatewayType === "on-prem" ?
                <BooleanInput
                    source="secureOnPremNetworks"
                    label="tesseract.appliances.settings.advanced.secureOnPremNetwork"
                    description={translate("tesseract.appliances.settings.advanced.descriptions.secureOnPremNetwork")}
                /> : null}
            {!isSc ? (
                <BooleanInput
                    source="disableSecurityPolicy"
                    label="tesseract.appliances.settings.advanced.disableSecurityPolicy"
                    description={translate("tesseract.appliances.settings.advanced.descriptions.disableSecurityPolicy", {context: applianceType})}
                />
            ) : null}
            {!isSc ? (
                <BooleanInput
                    source="disableIntrusionPrevention"
                    label="tesseract.appliances.settings.advanced.disableIpsPolicy"
                    description={translate("tesseract.appliances.settings.advanced.descriptions.disableIpsPolicy", {context: applianceType})}
                    disable={(value, data) => data && data.disableSecurityPolicy}
                />
            ) : null}
            {!isSc ? (
                <BooleanInput
                    source="disableOMS"
                    label="tesseract.appliances.settings.advanced.disableOMS"
                    description={translate("tesseract.appliances.settings.advanced.descriptions.disableOMS", {context: applianceType})}
                />
            ) : null}
            {!isSc ? (
                <BooleanInput
                    source="disableSyslogStreaming"
                    label="tesseract.syslog.syslogOverride"
                    description={translate("tesseract.syslog.descriptions.syslogOverride", {context: applianceType})}
                />
            ) : null}
            {isProxyConnect && ((!isSc && applianceType === "site") || gatewayType === "on-prem") ?
                <>
                    <BooleanInput
                        source="proxyConnect"
                        label="tesseract.appliances.settings.advanced.proxyConnect"
                        description={translate("tesseract.appliances.settings.advanced.descriptions.proxyConnect")}
                    />
                    <TextArrayInput
                        source="proxyConnectSockets"
                        label="tesseract.appliances.settings.advanced.proxyConnectSockets"
                        description={translate("tesseract.appliances.settings.advanced.descriptions.proxyConnectSockets")}
                        validate={validateArray(ipv4SocketValidator)}
                        disable={(value, data) => !data?.proxyConnect}
                    />
                </> : null}
              {isReporting && ((!isSc && applianceType === "site") || gatewayType === "on-prem") ?
                <>
                    <BooleanInput
                        source="reporting"
                        label="tesseract.appliances.settings.advanced.reporting"
                        description={translate("tesseract.appliances.settings.advanced.descriptions.reporting")}
                    />
                </> : null}
            {(applianceType === "site" || gatewayType === "on-prem") ?
                <div className={classes.bootstrapConfigButton}>
                    <CreateEditButtonDialog
                        create
                        label="tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.buttonLabel"
                        component={DownloadBootstrapConfigDialog}
                        applianceType={applianceType}
                        serials={serials}
                        isSc={isSc}/>
                </div> : null}
        </React.Fragment>
    );
};


export default AdvancedSettingsTab;