import {
    ConnectedForm,
    DialogBody,
    SelectInput,
    TextArrayInput,
    TextInput,
    useGlobalParam,
    validateMaxLengthMemo,
} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {Trans, useTranslation} from "react-i18next";
import {Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Grid} from "@barracuda-internal/bds-core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    headerText: {
        margin: theme.spacing(2),
        color: "#646464"
    },
    divider: {
        width: "auto",
        margin: "auto 16px"
    },
    textContainer: {
        textAlign: "left",
        paddingBottom: 12,
        margin: "20px 16px"
    },
    locationContent: {
        display: "flex",
        width: "100%",
        padding: "10px 0 0",
        fontSize: "inherit",
        fontWeight: "600"
    },
}));

export interface CreateEditLocationsProps {
    onClose: () => void,
    onSuccess: () => void,
    create?: boolean,
    id?: number | string,
}

export const CreateEditLocations = ({onClose, onSuccess, create, id}: CreateEditLocationsProps) => {
    const [virtualWanId,] = useGlobalParam("filter.virtualWanId");
    const [translate] = useTranslation();
    const classes = useStyles();
    // const IPTypes = sortBy(getArrayDataContent(useCrudProps(apiResources.locationTypes)[0]), "name");
    const IPTypes= [
      {key: "static", value: "Static"},
      {key: "dynamic", value: "Dynamic"}
    ];

    return (
        <DialogBody
            title={create ? "tesseract.network.locations.dialog.create" : "tesseract.network.locations.dialog.edit"}
            onClose={onClose}
            form
        >

            <ConnectedForm
                resource={apiResources.addLocations}
                formatRequestData={(data: any) => ({
                    ...data,
                    virtualWanId
                })}

                params={create ? undefined : {id}}
                onSubmitSuccess={() => {
                    onSuccess();
                    onClose();
                }}
                onCancel={onClose}
                create={create}
            >
                <Grid item xs={12} className={classes.textContainer}>
                    <Typography variant="body1">
                        {translate("tesseract.network.locations.dialog.createContent")}
                    </Typography>
                </Grid>
                <TextInput
                    label="tesseract.network.locations.dialog.name"
                    description="tesseract.network.locations.dialog.description.name"
                    source="name"
                    validate={validateMaxLengthMemo(255)}
                    isRequired
                />
                 <SelectInput
                    label="tesseract.network.locations.dialog.type"
                    description="tesseract.network.locations.dialog.description.type"
                    source="type"
                    choices={IPTypes}
                    optionValue={"key"}
                    optionText={"value"}
                    isRequired
                />
                <TextArrayInput
                    source={"addresses"}
                    label="tesseract.network.locations.dialog.ipAddress"
                    description={"tesseract.network.locations.dialog.description.ipAddress"}
                    isRequired
                    hide={(_value: any, data: any) => (data?.type === "dynamic")}
                />
                <Grid item xs={12} className={classes.textContainer}>
                    <Typography variant="body1">
                        {translate("tesseract.network.locations.table.dnsProxyContent")}
                    </Typography>
                    <Typography variant="body2"  className={classes.locationContent}>
                        <Trans i18nKey="tesseract.network.locations.table.primaryDNS" values={{primaryDNS: "1.0.0.1"}}>
                            <strong />
                        </Trans>
                    </Typography>
                    <Typography variant="body2" className={classes.locationContent}>
                         <Trans i18nKey="tesseract.network.locations.table.secondaryDNS" values={{secondaryDNS: "2.0.0.2"}}>
                            <strong />
                        </Trans>
                    </Typography>
                </Grid>
            </ConnectedForm>
        </DialogBody>
    );
};

export default CreateEditLocations;