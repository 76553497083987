import {
    FormSection,
    TextArrayInput,
    validateArray,
    validateCIDR
} from "@cuda-react/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";

const ReportCreatorAclSection = ({className}: {className: string}) => {
    const [translate] = useTranslation();

    return (
        <FormSection title="tesseract.settings.tabs.reportCreatorAcl.title" newStyle>
            <Typography className={className}>
                {translate("tesseract.settings.tabs.reportCreatorAcl.description")}
            </Typography>
            <TextArrayInput
                source="reportCreator.acls"
                label="tesseract.settings.tabs.reportCreatorAcl.subMenu"
                // @ts-ignore
                validate={validateArray[validateCIDR]}
            />
        </FormSection>
    );
};

export default ReportCreatorAclSection;